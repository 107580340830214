<template>
  <div class="map-panel map-header-panel title-text justify-content-center flex-row d-flex">
    <b-link class="hidden-link" to="/">
      <div class="text-center">
      Генератор архитектурных концепций
        </div>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  props: {
    showTopBar: Boolean,
  },
}
</script>

<style scoped>
@import '../assets/styles/map.css';
.map-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  z-index: 1000 !important;
  background: #FEFEFE;
  /* Shadows */

  box-shadow: 0px 6px 16px rgba(212, 218, 220, 0.64);
  backdrop-filter: blur(3px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px !important;
}
.map-header-panel {
  position: absolute;
  width: 400px;
  height: 48px;
  left: 50%;
  top: 16px;

  transform: translate(-50%, 0);
}


</style>