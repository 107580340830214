const GeometryUtils = {
    methods: {
        getCentroid(arr) {
            return arr.reduce(function (x, y) {
                return [ x[0] + y[0] / arr.length, x[1] + y[1] / arr.length ]
            }, [0, 0]);
        },

        invertPolygonCoords(geoJson) {
            let coordinates = geoJson.geometry.coordinates;
            for (let ringIdx = 0; ringIdx < coordinates.length; ++ringIdx) {
                for (let coordIdx = 0; coordIdx < coordinates[ringIdx].length; ++coordIdx) {
                    let pointCoordinates = coordinates[ringIdx][coordIdx];
                    if (!pointCoordinates) {
                        // most likely empty polygon
                        continue;
                    }
                    coordinates[ringIdx][coordIdx] = [pointCoordinates[1], pointCoordinates[0]];
                }
            }
            return geoJson;
        },

        invertLineStringCoords(geoJson) {
            let coordinates = geoJson.geometry.coordinates;
            for (let coordIdx = 0; coordIdx < coordinates.length; ++coordIdx) {
                let pointCoordinates = coordinates[coordIdx];
                if (!pointCoordinates) {
                    // most likely empty polygon
                    continue;
                }
                coordinates[coordIdx] = [pointCoordinates[1], pointCoordinates[0]];
            }
            return geoJson;
        },
    }
}

export default GeometryUtils;