import VueRouter from "vue-router";
import Map from "@/pages/Map";
import About from "@/pages/About";
import Contacts from "@/pages/Contacts";
import Editor from "@/pages/Editor";
import Result from "@/pages/Result";
import Vue from "vue";

Vue.use(VueRouter)

const router = new VueRouter({
    routes:  [

        {
            path: '/', component: Map,
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/about',
            component: About,
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/contacts',
            component: Contacts,
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/editor',
            name:"editor",
            component: Editor,
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/result/:resultId?',
            name:"result",
            component: Result,
            meta: {
                requiresPermissions: []
            }
        }
    ]
});

export default router