import Axios from '../axios'

const MapAPI = {
    methods: {

        checkShape: function(vertices) {
            return Axios.post(process.env.VUE_APP_API_URL + `/map/checkShape`, vertices);
        },

        generateConcept: function(request) {
            return Axios.post(process.env.VUE_APP_API_URL + `/generation/start`, request);
        },

        getGenerationResult: function(taskId) {
            return Axios.get(process.env.VUE_APP_API_URL + `/generation/` + taskId)
        },

        getSupportedModels: function() {
            try {
                return Axios.get(process.env.VUE_APP_API_URL + '/params/getSupportedModels');

            } catch (error) {
                console.error('Error fetching supported model:', error);
                return [];
            }
        },

        getAreaLimits: function () {
            try {
                return Axios.get(process.env.VUE_APP_API_URL + '/params/getAreaLimits');

            } catch (error) {
                console.error('Error fetching area limits:', error);
                return null;
            }
        },
    }
};

export default MapAPI