<template class="flex-grow-1" >
    <div style="height: 100%" @keydown.esc="hideBuildingInfo">

      <b-card v-if="areaGeometryInfoPopup == null && !generating && !error" class="map-info" style="width: 530px; height: 220px">
        <div class="d-flex flex-column align-items-center justify-content-around">
        <div>
          <b class="chooseParameters">Выберите участок</b>
        </div>


        <div v-if="drawingMode === 'none'" class="info-text mt-3" >
          Этот сервис генерирует концепции зонирования жилых районов
          при помощи ИИ. Генерация учитывает выбранную модель застройки и окружение проекта и позволяет
          быстро создать концепцию.
        </div>

          <div v-if="drawingMode === 'polygon'" class="info-text mt-3" >
            Последовательно отметьте на карте точки, чтобы
            очертить территорию. Для окончания ввода замкните
            линию, нажав на первую точку. Площадь должна быть
            в диапазоне от 10 до 100 га.
          </div>


        <div class="d-flex align-items-start">

          <div class="d-flex flex-row mt-3">
            <b-button v-if="drawingMode === 'none'"
                      variant="primary"
                      @click="startPolygon"
                      class="btn-port-primary">
              <img width="18" height="18" src="@/assets/icons/pen.png">
              Указать локацию
            </b-button>
            <b-button v-if="drawingMode !== 'none'" class="btn-port-load" variant="primary" @click="clearPolygon">Отмена</b-button>

          </div>
        </div>
        </div>
      </b-card>

      <editable-map ref="map"
                    editable
                    :zoom="zoom"
                    :maxZoom="18"
                    :center="center"
                    class="flex-grow-1"
                    :options="{attributionControl: false, zoomControl: false}"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-control-attribution position="bottomleft"></l-control-attribution>
        <l-geo-json :geojson="availableArea" v-if="availableArea" :options-style="{color: '#88ff88', fillOpacity: 0.1}" pane="availableZonePane"></l-geo-json>
        <l-control-scale position="topright" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
      </editable-map>

    </div>
</template>

<script>
import L, {latLng} from "leaflet";
import {LControlAttribution, LControlScale, LGeoJson, LTileLayer} from 'vue2-leaflet';
import {EditableMap} from "vue2-leaflet-editable";
import MapAPI from "../mixins/MapAPI.js";

import pencilIcon from "@/assets/pencil.svg";
import areaIcon from "@/assets/area.svg";
import GeometryUtils from "@/mixins/GeometryUtils";

export default {
        name: "MapComponent.vue",
        components: {
            LTileLayer,
            LControlScale,
            EditableMap,
            LControlAttribution,
          LGeoJson
        },
        mixins: [ MapAPI, GeometryUtils ],
        data() {
            return {
                pencilIcon: pencilIcon,
                areaIcon: areaIcon,
                loading: false,
                zoom: 11,
                center: latLng(59.938951, 30.315635 ),
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                fillColor: "#e4ce7f",
              availableArea: null,
              drawingMode: 'none', // none, polygon or point
              polygonName: '',
              polygonTags: {},
              polygonArea: 0.0,
              polygonDrawing: false,
              polygonInfoPopup: null,
              jsonResponse: '',
              generating: false,
              areaGeometry: null,
              areaGeometryInfoPopup: null,
              error: null,
              timerEnabled: false,
              timerCount: 0,
              estimatedTime: 0,
              checkedPoly:true,
              isVisible: false,

              idToLoad: ""
            };
        },
        methods: {
          areaText(m2) {
            if (m2 > 10000) {
              return (m2 / 10000).toFixed(2) + ' га (' + Number(m2.toFixed(0)).toLocaleString('ru-RU') + ' м<sup>2</sup>)';
            } else {
              return Number(m2.toFixed(2)).toLocaleString('ru-RU') + ' м<sup>2</sup>';
            }
          },
          countTimer() {
            setTimeout(() => {
              this.timerCount++;
              if (this.timerEnabled) {
                this.countTimer()
              }
            }, 1000);
          },
          generate() {
            this.$router.push({
              name: 'editor',
              params: {
                portArea: this.polygonArea,
                portGeometry: this.areaGeometry.toGeoJSON(),
                portPolygon: this.areaGeometry
              }
            })
          },


          startPolygon() {
            let mapObject = this.$refs.map.mapObject;
            this.areaGeometry = mapObject.editTools.startPolygon();
            this.areaGeometry.on('editable:drawing:commit', this.updateAreaAndContent);
            this.areaGeometry.on('click', this.showPolygonPopup);

            this.areaGeometry.options.color = '#17A2B8';
            this.drawingMode = 'polygon';
            this.polygonDrawing = true;
            this.areaGeometry.on('editable:drawing:commit', this.showPolygonPopup);
          },
          clearPolygon() {
            let mapObject = this.$refs.map.mapObject;
            mapObject.editTools.stopDrawing();
            if (this.areaGeometry) {
              this.areaGeometry.remove()
            }
            this.areaGeometry = null;
            this.generating = false;
            this.timerEnabled = false;
            this.polygonDrawing = false;
            this.error = null;
            if (this.polygonInfoPopup != null) {
              this.polygonInfoPopup.remove();
              this.polygonInfoPopup = null;
            }
            this.drawingMode = 'none';
            if (this.areaGeometryInfoPopup != null) {
              this.areaGeometryInfoPopup.remove();
              this.areaGeometryInfoPopup = null;
            }

          },



          showPolygonPopup() {
            if (this.areaGeometryInfoPopup != null || this.generating) return;
            let context = this;
            this.areaGeometryInfoPopup = new L.Popup({
              closeOnClick: false
            }).setContent(
                this.popupSkeleton
            ).on("remove", function () {
              context.areaGeometryInfoPopup = null;
            });

            let mapObject = this.$refs.map.mapObject;
            mapObject.on('click', function() {
              context.updateAreaAndContent();
            });

            this.areaGeometryInfoPopup.on('popupopen', this.updateAreaAndContent);

            this.checkShape(this.polygonGeoJSON).then(async rz => {
              if (rz.data.code === 0) {
                this.polygonName = rz.data.name;
                this.polygonArea = rz.data.area;
                if (context.checkedTB) {
                  this.polygonTags = rz.data.tags;
                }
                const areaLimits = await this.getAreaLimits();


                if (rz.data.area/10000 > areaLimits.data.maxAreaHa) {
                  this.areaGeometryInfoPopup.setContent(this.areaTooBigText);
                  const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives
                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.clearPolygon();
                  };
                } else if (rz.data.area/10000 < areaLimits.data.minAreaHa) {
                  this.areaGeometryInfoPopup.setContent(this.areaTooSmallText);
                  const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives

                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.clearPolygon();
                  };
                } else {
                  this.areaGeometryInfoPopup.setContent(this.polygonPopupText);
                  const btn = document.getElementById("popupButton");
                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.areaGeometry.disableEdit();
                    context.generate();
                  };
                }

                const closeButton = this.areaGeometryInfoPopup._closeButton;
                closeButton.addEventListener("click", function(e) {
                  e.preventDefault();
                  context.clearPolygon();
                });

              }
            });

            this.areaGeometryInfoPopup.setLatLng(this.areaGeometry.getBounds().getCenter());
            mapObject.addLayer(this.areaGeometryInfoPopup);
          },

          updateAreaAndContent() {
            this.checkShape(this.polygonGeoJSON).then(async rz => {
              if (rz.data.code === 0) {
                this.polygonName = rz.data.name;
                this.polygonArea = rz.data.area;

                if (this.checkedTB) {
                  this.polygonTags = rz.data.tags;
                }

                const areaLimits = await this.getAreaLimits();
                const context = this;

                if (rz.data.area / 10000 > areaLimits.data.maxAreaHa) {
                  this.areaGeometryInfoPopup.setContent(this.areaTooBigText);
                  const btn = document.getElementById("popupButton");
                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.clearPolygon();
                  };
                } else if (rz.data.area / 10000 < areaLimits.data.minAreaHa) {
                  this.areaGeometryInfoPopup.setContent(this.areaTooSmallText);
                  const btn = document.getElementById("popupButton");
                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.clearPolygon();
                  };
                } else {
                  this.areaGeometryInfoPopup.setContent(this.polygonPopupText);
                  const btn = document.getElementById("popupButton");
                  btn.onclick = function (e) {
                    e.preventDefault();
                    context.areaGeometry.disableEdit();
                    context.generate();
                  };
                }
              }
            });
          },


        },
        computed: {
          polygonPopupText() {
            let rz = '<b class="title-area">' + this.polygonName + '</b>' +
                '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>';
            rz += '<a href="#" id="popupButton" class="edit-button" >Перейти к вводу данных</a>';
            return rz;
          },

          areaTooBigText() {
            return '<b class="title-area">' + this.polygonName + '</b>' +
                '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>'+
                '<div class="area-parameters" style="color: red">Ошибка! Полигон слишком большой!</div>' +
                '<a href="#" id="popupButton" class="edit-button">Сбросить полигон</a>';
          },
          areaTooSmallText() {
            return '<b class="title-area">' + this.polygonName + '</b>' +
                '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>'+
                '<div class="area-parameters" style="color: red">Ошибка! Полигон слишком маленький!</div>' +
                '<a href="#" id="popupButton" class="edit-button">Сбросить полигон</a>';
          },
          popupSkeleton() {
            return '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave" style="width: 100px"></div>' +
                '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave"></div>' +
                '<br><a href="#" class="popup-button disabled"><span class="spinner-border spinner-border-sm"></span></a>';
          },
          isPolygonEmpty() {
            let polygon = this.polygonGeoJSON;
            return !polygon.geometry.coordinates
                || polygon.geometry.coordinates.length < 1
                || !polygon.geometry.coordinates[0]
                || polygon.geometry.coordinates[0].length < 2;
          },

          /**
           * Swaps lonlat coord order to latlon which is expected by backend
           */
          polygonGeoJSON() {
            let geoJson = this.areaGeometry.toGeoJSON()
            return this.invertPolygonCoords(geoJson)
          }
        },
        mounted() {
          let mapObject = this.$refs.map.mapObject;
          let context = this;

          mapObject.on('editable:drawing:end', function () {
            if (this.drawingMode === 'polygon') {
              if (!context.isPolygonEmpty) {
                context.showPolygonPopup();
              } else {
                context.clearPolygon();
              }
            } else if (this.drawingMode === 'point') {
              context.showPointPopup();
            }
          });
          mapObject.on("click", function() {
            context.clickedPoint = null;
            context.clickedPolygon = null;
            context.clickedDistance = null;
            context.clickedDistanceGeometry = null;
          });

        }
    }
</script>

<style scoped>
@import '../assets/styles/map.css';
          sup {
            line-height: 1 !important;
          }

          /deep/ .btn-group-toggle input[type="radio"] {
            display: none;
          }

          .popup-button {
            display: block;
            width: 100%;
            height: 35px;
            padding: 8px 16px;
            text-align: center;
            border-radius: 50px;
            background-color: #17A2B8;
            color: white !important;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
          }
          .popup-button.disabled {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
          }

          h4 {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px !important;
            color: #7E8B96;
            display: flex;
            align-self: stretch;
          }

          .area-label > img {
            margin-right: 6px;
            margin-top: 3px;
          }

          .map-info {
            position: absolute !important;
            bottom: 16px !important;
            left: 50% !important;
            z-index: 1000 !important;
            width: 710px;
            transform: translate(-50%, 0);
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
            background-color: rgba(255, 255, 255, 0.8);
            border: none !important;
            border-radius: 20px !important;
          }

          .popup-button.disabled {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
          }

          .map-info > .card-body {
            padding: 27.5px 40px 27.5px 40px;
          }

          .about-panel {

            position: absolute;
            width: 155px;
            height: 48px;
            right: 16px;
            bottom: 16px;
          }

          .btn-port-primary {
            width:  230px;
            height:  48px;

            justify-content: center;
            align-items: center;
            padding: 12px 26px;
            gap: 12px;
            margin-right: 10px;

            background: #4c31a4;
            color: #fefefe;
            backdrop-filter: blur(3.5px);
            border: 1px solid lightgray;
            border-radius: 12px;
          }
          .btn-port-load {
            width:  230px;
            height:  48px;
            justify-content: center;
            align-items: center;
            padding: 12px 26px;
            gap: 12px;
            background: #fefefe;
            color: #4c31a4;
            backdrop-filter: blur(3.5px);
            border-radius: 12px;
            font-family: Mulish;
            font-size: 16px;
            border: 1px solid lightgray;
            margin-bottom: 16px;
            font-weight: bold;
            letter-spacing: 0;
          }

</style>