<template>
    <div class="container m-5 text-left">
        <b-row>
            <b-col>
               <p>
                   Описание проекта
               </p>
            </b-col>
        </b-row>
    </div>

</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

</style>