<template class="flex-grow-1">
  <div style="height: 100%">
    <LoadingPopup :loading="downloadLoading"></LoadingPopup>

    <b-sidebar id="sidebar-1" title="Sidebar" left no-header visible sidebar-class="left-info-panel" width="500px" z-index="1001">
      <b-container class="pt-3"  style="background: #FEFEFE;" >
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-park-outline" @click="$router.push('/')">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png" style="padding: 4px">
              <span class="ml-2 info-text" style="font-weight: bold; padding-left: 15px"> Выйти из режима создания территории</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png" style="width: 79px; height: 30px">
        </div>

        <h1 class="very-large-title-text" style="margin-top: 20px; margin-bottom: 20px">Новая генерация</h1>
        <div v-if="showObjectList">

          <div class="info-text" >

            <b-container id="sidebar-1" title="Sidebar" right no-header visible width="500px" z-index="1000">
                <b-row>
                  <b-col lg="6">
                    <div v-for="param in tep" :key="param.id">

                      <template>
                        <div v-if="param.uniqueId === 'area'">
                          <div
                              v-for="paramInside in param.items"
                              :key="paramInside.id"
                          >
                            <div class="row">
                              <div class="col-8">
                                <div class="result-panel flex-column" style="margin-bottom: 10px; background: #F3F7F3; width: 450px; height: 100px; align-items: start">
                            <span class="name-span info-text">
                              {{ paramInside.name }}:</span>
                                  <span class="result-parameters">
                                  <div v-if="paramInside.value === 'Нет'" >Нет</div>
                                      <div v-else-if="paramInside.value === 'Да'" >Да</div>
                                  <div v-else>{{ Math.round((parseFloat(paramInside.value) + Number.EPSILON)) }}</div>

                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </b-col>
                </b-row>
            </b-container>
          </div>


          <div v-b-toggle.accordion-1 class="d-flex flex-row align-items-center p-3 editor-section" style="margin-top: 20px">
            <div class="result-param-name flex-grow-1" style="padding-left: 10px">Зонирование</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>

          </div>
          <b-collapse id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel"
          >

            <div class="info-text" >
              <b-container id="sidebar-1" title="Sidebar" right no-header visible width="450px" z-index="1000">
                <b-container style="margin-top: 15px">
                  <b-row>
                    <b-col lg="6">
                      <div v-for="param in tep" :key="param.id">
                        <template>
                          <div v-if="param.uniqueId === 'zonesAreas'">
                          <div
                              v-for="(paramInside, newIndex) in param.items"
                              :key="paramInside.id"
                              @mouseover="expandInfoPanel(paramInside)"
                              @mouseleave="collapseInfoPanel(paramInside)"
                          >
                            <div v-if="newIndex % 2 === 0 " class="row">
                              <div class="col-8">
                                <div class="result-panel" :style="infoPanelStyles(paramInside)" style="margin-bottom: 10px; background: #F3F7F3; min-height: 110px">
                            <span class="name-span info-text">
                             <div class="icon" :style="{ background: idToColorMap[paramInside.uniqueId] || itemColors[newIndex % itemColors.length]
         }" style="display: inline-block; margin-right: 5px"></div>
                              {{ paramInside.name }}:</span>
                                  <span class="result-parameters">
                                  <div v-if="paramInside.value === 'Нет'" >Нет</div>
                                      <div v-else-if="paramInside.value === 'Да'" >Да</div>
                                  <div v-else>{{ Math.round((parseFloat(paramInside.value) + Number.EPSILON))}}</div>
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        </template>
                      </div>
                    </b-col>

                    <b-col lg="6">
                      <div v-for="param in tep" :key="param.id">
                        <template>
                          <div v-if="param.uniqueId === 'zonesAreas'">
                            <div
                                v-for="(paramInside, newIndex) in param.items"
                                :key="paramInside.id"
                                @mouseover="expandInfoPanel(paramInside)"
                                @mouseleave="collapseInfoPanel(paramInside)"
                            >
                              <div v-if="newIndex % 2 !== 0 " class="row">
                                <div class="col-8">
                                  <div class="result-panel" :style="infoPanelStyles(paramInside)" style="margin-bottom: 10px; background: #F3F7F3; min-height: 110px">
                            <span class="name-span info-text">
                              <div class="icon" :style="{ background: idToColorMap[paramInside.uniqueId] || itemColors[newIndex % itemColors.length]
         }" style="display: inline-block; margin-right: 5px"></div>
                              {{ paramInside.name }}:</span>
                                    <span class="result-parameters">
                                  <div v-if="paramInside.value === 'Нет'" >Нет</div>
                                      <div v-else-if="paramInside.value === 'Да'" >Да</div>
                                  <div v-else>{{ Math.round((parseFloat(paramInside.value) + Number.EPSILON))}}</div>
                                </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-container>

            </div>
          </b-collapse>

          <div v-b-toggle.accordion-2 class="d-flex flex-row align-items-center p-3 editor-section" style="margin-top: 20px">
            <div class="result-param-name flex-grow-1" style="padding-left: 10px">Количество объектов инфраструктуры</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>
          </div>

          <b-collapse id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
          >
            <div class="info-text" >
              <b-container id="sidebar-1" title="Sidebar" right no-header visible width="450px" z-index="1000">
                <b-container style="margin-top: 15px">
                  <b-row>
                    <b-col lg="6">
                      <div v-for="param in tep" :key="param.id">

                        <template>
                          <div v-if="param.uniqueId === 'objectsCount'">
                            <div
                                v-for="(paramInside, newIndex) in param.items"
                                :key="paramInside.id"
                                @mouseover="expandInfoPanel(paramInside)"
                                @mouseleave="collapseInfoPanel(paramInside)"
                            >
                              <div v-if="newIndex % 2 === 0 " class="row">
                                <div class="col-8">
                                  <div class="result-panel" :style="infoPanelStyles(paramInside)" style="margin-bottom: 10px; background: #F3F7F3; min-height: 110px">
                            <span class="name-span info-text">
                               <div class="icon" :style="{ background: idToColorMap[paramInside.uniqueId] || itemColors[newIndex % itemColors.length]
         }" style="display: inline-block; margin-right: 5px"></div>
                              {{ paramInside.name }}:</span>
                                    <span class="result-parameters">
                                  <div v-if="paramInside.value === 'Нет'" >Нет</div>
                                      <div v-else-if="paramInside.value === 'Да'" >Да</div>
                                  <div v-else>{{ Math.round((parseFloat(paramInside.value) + Number.EPSILON))}}</div>

                                </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-col>

                    <b-col lg="6">
                      <div v-for="param in tep" :key="param.id">
                        <template>
                          <div v-if="param.uniqueId === 'objectsCount'">
                            <div
                                v-for="(paramInside, newIndex) in param.items"
                                :key="paramInside.id"
                                @mouseover="expandInfoPanel(paramInside)"
                                @mouseleave="collapseInfoPanel(paramInside)"
                            >
                              <div v-if="newIndex % 2 !== 0 " class="row">
                                <div class="col-8">
                                  <div class="result-panel" :style="infoPanelStyles(paramInside)" style="margin-bottom: 10px; background: #F3F7F3; min-height: 110px">
                            <span class="name-span info-text">
                               <div class="icon" :style="{ background: idToColorMap[paramInside.uniqueId] || itemColors[newIndex % itemColors.length]
         }" style="display: inline-block; margin-right: 5px"></div>
                              {{ paramInside.name }}:</span>
                                    <span class="result-parameters">
                                  <div v-if="paramInside.value === 'Нет'" >Нет</div>
                                      <div v-else-if="paramInside.value === 'Да'" >Да</div>
                                  <div v-else>{{ Math.round((parseFloat(paramInside.value) + Number.EPSILON))}}</div>
                                </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-container>
            </div>
          </b-collapse>



          <b-dropdown v-if="id !== null" id="dropdown-1" text="Скачать результаты генерации"  toggle-class="result" variant="none" class="result" no-caret style="margin-top: 20px; position: relative; z-index: 2" >
            <template #button-content>
              <div class="d-flex flex-row align-content-center justify-content-center">
                <img width="18" height="18" src="@/assets/icons/download.png">
                <div class="result-text">Скачать результаты генерации</div>
              </div>
            </template>
            <b-dropdown-item @click="downloadPlanningGeoJSON">GeoJSON с планировкой</b-dropdown-item>
          </b-dropdown>

        </div>

        <div v-if="!showObjectList">
          <div v-if="selectedType">
          <h1 class="very-large-title-text " style="margin-top: 30px; margin-bottom: 20px">{{ selectedType }}</h1>
          </div>
          <b-container>
            <b-row>
              <b-col lg="6">
                <div>
                  <template>
                    <div v-if="paramsAreVisible" class="row" style="margin-bottom: 20px">
                      <div class="col-8">
                        <div class="selected-resParameters" >
                            <span class="name-span title-text">
                              {{selectedParamName}}</span>
                          <span class="result-parameters">
                              {{Math.round((parseFloat(selectedParamValue) + Number.EPSILON))}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <img v-if="selectedImageSrc" class="frame" :src= "'/img/' + selectedImageSrc" alt="Изображение">
          <button class="result" style="margin-top: 15px" @click="returnBack()">
              <span class="title-text" style="font-weight: bold" > Вернуться</span>
          </button>
        </div>

      </b-container>
    </b-sidebar>

    <div id="app">
      <div id="map"></div>
    </div>

  </div>
</template>

<script>
import {latLng} from "leaflet";
import maplibregl from 'maplibre-gl';
import MapAPI from "@/mixins/MapAPI";
import axiosInstance from "@/axios";
import LoadingPopup from "@/components/LoadingPopup";
import MapStyles from "@/mixins/MapStyles";
import * as turf from "@turf/turf";

export default {
  name: "Result",
  components: {
    LoadingPopup
  },
  mixins: [
    MapAPI,
    MapStyles
  ],
  data() {
    return {
      selectedFile: null,
      id: null,
      downloadLoading: false,
      areaBounds: null,
      resultObjects: null,
      resultZones: null,
      showObjectList: true,
      selectedType: null,
      selectedImageSrc: null,
      selectedParamName: null,
      selectedParamValue: null,
      paramsAreVisible: false,
      tep: {},
      map:null,
      line: null,
      expandedItem: null,
      YOUR_MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiZXNtaXJub3YiLCJhIjoiY2t1d2luYW0wMGdjdDJvbXJsMGVyc2lmdyJ9.wtMpaXCT-IvPO3eTDglP0Q',
      colorIds: {
        //change these to set colors of the markers, zones and objects
        'high_residential_zone': 'rgba(64, 64, 64, 0.8)',
        'low_residential_zone': 'rgba(200, 200, 200, 0.8)',
        'greenery_zone': 'rgba(50, 205, 50, 0.8)',
        'commercial_zone': 'rgba(173, 216, 230, 0.8)',
        'social_zone': 'rgba(245, 245, 220, 0.8)',
        'dominant_zone': 'rgba(255, 255, 153, 0.8)',
        'residential_obj': 'rgba(173, 216, 230, 0.8)',
        'commercial_obj': 'rgba(230, 230, 250, 0.8)',
        'social_obj': 'rgba(124, 252, 0, 0.8)',
      },
      idToColorMap: {
        'zoneArea.high_residential': null,
        'zoneArea.low_residential': null,
        'zoneArea.greenery': null,
        'zoneArea.commercial': null,
        'zoneArea.social': null,
        'zoneArea.dominant': null,
        'residentialCount': null,
        'commercialCount': null,
        'socialCount': null,
      },
      itemColors: ["red", "blue", "green", "orange", "pink", "cornflowerblue", "darkcyan", "aqua", "greenyellow", "rebeccapurple", "teal", "violet", "peru", "plum"],
      zoom: 15,
      fillColor: 'lightcoral',
      center: latLng(59.939468, 30.308769),
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }
  },

  mounted() {
    this.setColors()
    this.loadFromId()

    this.id = this.$route.params.resultId;

    self.map = new maplibregl.Map({
      style: `https://api.mapbox.com/styles/v1/mapbox/streets-v11?access_token=${this.YOUR_MAPBOX_ACCESS_TOKEN}`,
      center: [30.308769, 59.939468],
      zoom: 15.5,
      pitch: 45,
      bearing: -17.6,
      container: 'map',
      antialias: true
    });

    self.map.on('load', () => {
      self.map.addSource('osm-tiles', {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      });

      self.map.addLayer({
        id: 'osm-layer',
        type: 'raster',
        source: 'osm-tiles',
      });
      });
  },

  methods: {

    loadFromId() {
      this.id = this.$route.params.resultId;
      let self = this
      this.getGenerationResult(this.id).then(rz => {
        if (rz.status === 200 && rz.data.code === 0){
          self.areaBounds = {
            type: "FeatureCollection",
            features: [{
              type: "Feature",
              geometry: rz.data.result.border,
              properties: {
                type: "border"
              }
            }]
          }
          // convert to linestring so that click on inner polygon area does not trigger border
          self.areaBounds.features[0].geometry.type="LineString"
          self.areaBounds.features[0].geometry.coordinates=self.areaBounds.features[0].geometry.coordinates[0]

          self.resultObjects = rz.data.result.objects
          self.resultZones = rz.data.result.zones
          self.tep = rz.data.result.economyParameters
          self.fitBounds()
          this.center = turf.center(self.areaBounds.features[0].geometry);

          self.map = new maplibregl.Map({
            style: `https://api.mapbox.com/styles/v1/mapbox/streets-v11?access_token=${this.YOUR_MAPBOX_ACCESS_TOKEN}`,
            center: this.center.geometry.coordinates,
            zoom: 15.5,
            pitch: 45,
            bearing: -17.6,
            container: 'map',
            antialias: true
          });

          self.map.on('load', () => {
            self.map.addSource('osm-tiles', {
              type: 'raster',
              tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
              tileSize: 256,
            });

            self.map.addLayer({
              id: 'osm-layer',
              type: 'raster',
              source: 'osm-tiles',
            });

            self.resultZones.features.forEach((feature, index) => {
              const layerId = `resultZoneLayer_${index}`;
              const zoneColor = this.getColorForZone(feature.properties.zone);

              self.map.addSource(layerId, {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: [feature],
                },
              });

              self.map.addLayer({
                'id': layerId,
                'type': 'fill',
                'source': layerId,
                'layout': {},
                'paint': {
                  'fill-color': zoneColor,
                  'fill-outline-color': 'blue',
                  'fill-opacity': 0.8,
                },
              });
              self.map.on('click', layerId, () => {
                this.handleZoneClick(feature);
              });
            });

            self.resultObjects.features.forEach((feature, index) => {
              const layerId = `resultObjectLayer_${index}`;
              const levels = feature.properties.levels || 20;
              const buildingType = feature.properties.building || 'other';

              switch (buildingType) {
                case 'residential':
                  this.fillColor = this.colorIds.residential_obj;
                  break;
                case 'commercial':
                  this.fillColor = this.colorIds.commercial_obj;
                  break;
                case 'social':
                  this.fillColor = this.colorIds.social_obj;
                  break;
                default:
                  this.fillColor = 'lightcoral';
              }

              self.map.addSource(layerId, {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: [feature],
                },
              });

              self.map.addLayer({
                'id': layerId,
                'type': 'fill-extrusion',
                'source': layerId,
                'layout': {},
                'paint': {
                  'fill-extrusion-color': this.fillColor,
                  'fill-extrusion-height': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    5,
                    0,
                    16,
                    levels*5 //*5 modifier for better visibility of 3d objects
                  ],
                  'fill-extrusion-base': 0,
                  'fill-extrusion-opacity': 1.0,
                }
              });
              self.map.on('click', layerId, () => {
                this.handleObjectClick(feature);
              });
            });

          });
        }
      })
    },

    setColors(){
      this.idToColorMap["zoneArea.high_residential"] = this.colorIds.high_residential_zone;
      this.idToColorMap["zoneArea.low_residential"] = this.colorIds.low_residential_zone;
      this.idToColorMap["zoneArea.commercial"] = this.colorIds.commercial_zone;
      this.idToColorMap["zoneArea.greenery"] = this.colorIds.greenery_zone;
      this.idToColorMap["zoneArea.social"] = this.colorIds.social_zone;
      this.idToColorMap["zoneArea.dominant"] = this.colorIds.dominant_zone;
      this.idToColorMap.residentialCount = this.colorIds.residential_obj;
      this.idToColorMap.commercialCount = this.colorIds.commercial_obj;
      this.idToColorMap.socialCount = this.colorIds.social_obj
    },

    returnBack(){
      this.showObjectList = true;
    },

     getColorForZone(zone) {
       switch (zone) {
         case 'high_residential':
           return this.colorIds.high_residential_zone;
         case 'low_residential':
           return this.colorIds.low_residential_zone;
         case 'greenery':
           return this.colorIds.greenery_zone;
         case 'commercial':
           return this.colorIds.commercial_zone;
         case 'social':
           return this.colorIds.social_zone;
         case 'dominant':
           return this.colorIds.dominant_zone;
         default:
           return 'rgba(255, 165, 0, 0.8)';
       }
     },


    expandInfoPanel(paramInside) {
      this.expandedItem = paramInside;
    },
    collapseInfoPanel(paramInside) {
      if (this.expandedItem === paramInside) {
        this.expandedItem = null;
      }
    },
    infoPanelStyles(paramInside) {
      if (this.expandedItem === paramInside) {
        return {
          width: '210px',
          height: '100px',
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
        };
      } else {
        return {
          width: '210px',
          height: '100px',
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
        };
      }
    },

    fitBounds() {
    },

    handleZoneClick(zone) {
      this.showObjectList = false;
      this.selectedType = zone.properties.zone;
      switch (this.selectedType) {
        case 'high_residential':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'high_residential.jpg';
          this.selectedType = "Зона плотной застройки";
          break;
        case 'low_residential':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'low_residential.jpg';
          this.selectedType = "Зона малоэтажного жилья";
          break;
        case 'greenery':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'greenery.jpg';
          this.selectedType = "Зеленая зона";
          break;
        case 'commercial':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'commercial_area.jpg';
          this.selectedType = "Коммерческая зона";
          break;
        case 'social':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'social.jpg';
          this.selectedType = "Общественная зона";
          break;
        case 'dominant':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'dominant.png';
          this.selectedType = "Доминирующая зона";
          break;
      }

    },
    handleObjectClick(object) {
      this.showObjectList = false;
      this.selectedType = object.properties.building;
      switch (this.selectedType) {
        case 'residential':
          if (object.properties.levels >= 9) {
            this.selectedType = "Многоэтажный жилой дом";
          } else {
            this.selectedType = "Малоэтажный жилой дом"
          }
          this.paramsAreVisible = true;
          this.selectedParamValue = object.properties.levels;
          if (this.selectedParamValue >= 9){
            if (object.properties.model === "model1") {
              this.selectedImageSrc = 'tower-block.jpg';
            } else {
              this.selectedImageSrc = "soviet_multistorey.webp"
            }
          }
          else {
            if (object.properties.model === "model1") {
              this.selectedImageSrc = 'modern_low_residential.jpg';
            } else {
              this.selectedImageSrc = 'khrushchev_era_apartment.jpg';
            }
          }
          this.selectedParamName = "Этажность";
          break;
        case 'commercial':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'commercial.jpg';
          this.selectedType = "Коммерческие здания";
          break;
        case 'social':
          this.paramsAreVisible = false;
          this.selectedImageSrc = 'social_buildings.jpg';
          this.selectedType = "Общественные здания";
          break;
      }
    },

    openSaveFileDialog(axisoRespone, fileName) {
      const url = window.URL.createObjectURL(new Blob([axisoRespone.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },

    async downloadPlanningGeoJSON() {
      this.downloadLoading = true;
      let response = await axiosInstance({
        url: process.env.VUE_APP_API_URL + `/generation/objects/${this.id}`,
        responseType: 'blob'
      });
      this.downloadLoading = false;
      this.openSaveFileDialog(response, `objects_in_result_${this.id}.geojson`)
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';
.name-span {
  max-width: 350px;
  max-height: 50px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.name-span:hover {
  white-space: normal;
  max-height: none;
}
.icon{
  width: 16px;
  height: 16px;
  background: #2D2D2D;
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 50%;
}
.result-text{

  font-family: Mulish;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  margin-left: 5px;

}

.result{
  width:  480px;
  height:  48px;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  gap: 10px;
  background: #fefefe;
  color: #2D2D2D;
  backdrop-filter: blur(3.5px);
  border-radius: 12px;
  font-family: Mulish;
  font-size: 16px;
  border: 1px solid lightgray;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.selected-resParameters{
  width: 450px;
  height: 70px;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 8px;
  gap: 8px;
  z-index: 1000 !important;
  background: #F3F7F3;

  border-radius: 16px !important;
}
.frame{
  width: 450px;
  height: auto;
  border-radius: 16px !important;
}

.result-param-name{
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;

}
#map {
  height: 100vh;
}

</style>