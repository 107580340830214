<template class="flex-grow-1">
  <div style="height: 100%">


    <LoadingPopup :loading="loading" :seconds-passed="secondsPassed"></LoadingPopup>
    <b-sidebar id="sidebar-1" title="Sidebar" left no-header visible sidebar-class="left-info-panel" width="512px" z-index="1001">
      <b-container class="pt-3"  style="background: #FEFEFE;" >
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-park-outline" @click="$router.push('/')">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png" style="padding: 4px">
              <span class="ml-2 info-text" style="font-weight: bold; padding-left: 15px"> Сбросить и вернуться на начальный экран</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png" style="width: 79px; height: 30px">
        </div>

        <h1 class="very-large-title-text mt-5 mb-5">Новая концепция</h1>



        <div class="editor-section">
          <div v-b-toggle.accordion-2 class="d-flex flex-row align-items-center p-3">
            <div class="when-open selected-index large-title-text mr-2"></div>
            <div class="when-closed non-selected-index large-title-text mr-2"></div>

            <div class="large-title-text flex-grow-1" style="padding-left: 10px">Параметры генерации</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>
          </div>

          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel" >
            <div class="d-flex flex-column">
              <div class="d-flex flex-column m-3" style="align-items: flex-start">
                <div class="row mb-3">
                  <div class="col">
                    ML модель для генерации:
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <b-card img-src="/img/soviet.png"
                            img-alt="Card image"
                            img-top
                            img-height="200"
                            role="button"
                            :border-variant="(mlModelName === 'model2') ? 'primary' : ''"
                            @click="mlModelName = 'model2'">
                      <b-card-text>
                        "Купчино"
                      </b-card-text>
                    </b-card>
                  </div>
                  <div class="col-6">
                    <b-card img-src="/img/modern.png"
                            img-alt="Card image"
                            role="button"
                            img-height="200"
                            :border-variant="(mlModelName === 'model1') ? 'primary' : ''"
                            @click="mlModelName = 'model1'"
                            img-top>
                      <b-card-text>
                        "Мурино"
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <b-button @click="generate" class="mt-3 mb-2 generate-button">Запустить генерацию</b-button>

      </b-container>
    </b-sidebar>

    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  class="flex-grow-1"
                  :options="{attributionControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control-attribution position="bottomleft"></l-control-attribution>
      <l-geo-json :geojson="portBounds" v-if="portBounds" :options-style="{color: '#ff0000', fillOpacity: 0, dashArray: '10,5'}" ></l-geo-json>
      <l-control-scale position="topright" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>

  </div>
</template>

<script>
import {geoJSON, latLng} from "leaflet";
import {EditableMap} from "vue2-leaflet-editable";
import {LControlAttribution, LControlScale, LGeoJson, LTileLayer} from "vue2-leaflet";
import MapAPI from "@/mixins/MapAPI";
import LoadingPopup from "@/components/LoadingPopup";
import GeometryUtils from "@/mixins/GeometryUtils";


export default {
  name: "Editor",
  components: {
    EditableMap,
    LTileLayer,
    LControlAttribution,
    LControlScale,
    LGeoJson,
    LoadingPopup,
  },
  mixins: [
    MapAPI,
    GeometryUtils
  ],
  data() {
    return {
      portArea: 0,
      portBounds: null,
      portPolygon: null,
      resultObjects: null,
      resultZones: null,
      mlModelName: "model1",
      generationParams: {},
      tep: {},
      secondsPassed: 0,
      secondsInterval: null,
      isVisible:true,
      mlModelNames: [ {
        text: "Новостройки",
        value: "model1"
      }, {
        text: "Хрущевки",
        value: "model2"
      }
      ],

      loading: false,
      taskId: null,
      interval: null,
      zoom: 15,
      center: latLng(59.939468, 30.308769),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },

  async mounted() {
    this.portArea = this.$route.params.portArea
    this.portBounds = this.$route.params.portGeometry
    this.portPolygon = this.$route.params.portPolygon

    const supportedModelsResponse = await this.getSupportedModels()
    if (supportedModelsResponse) {
      this.mlModelNames = supportedModelsResponse.data.models;
    }

    let mapObject = this.$refs.map.mapObject

    let g = geoJSON({type: "FeatureCollection", features: [this.portBounds]});
    mapObject.fitBounds(g.getBounds())
  },

  methods: {


    async generate() {
      const areaLimits = await this.getAreaLimits();
      if (!areaLimits) {
        return;
      }
      let enteredArea = this.portArea / 10000;

      if (enteredArea < areaLimits.data.minAreaHa || enteredArea > areaLimits.data.maxAreaHa) {
        this.$bvToast.toast("Отправленные параметры площади не совпадают с минимальными или максимальными параметрами на сервере", {
          title: 'Error',
          autoHideDelay: 10000,
          variant: "danger"
        });
        return;
      }

      this.loading = true;
      this.secondsPassed = 0;
      this.secondsInterval = setInterval(() => {
        this.secondsPassed++;
      }, 1000)
      let request = {
        area: "",
        modelName: this.mlModelName
      }
      request.area = this.portBounds.geometry

      let self = this;
      this.generateConcept(request).then(rz => {
        if (rz.status === 200 && rz.data.code === 0) {
          this.taskId = rz.data.taskId
          this.interval = setInterval(this.queryPortResult, 3000)
        } else {
          this.showError(rz);
        }
      }).catch(function (error) {
        self.showError(error.response);
      })
    },

    showError(rz) {
      this.loading = false
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      if (this.secondsInterval) {
        clearInterval(this.secondsInterval);
        this.secondsInterval = null;
      }
      let message = "Произошла ошибка: "
      if (rz.status === 200) {
        message += rz.data.code
        if (rz.data.description) {
          message += ", " + rz.data.description;
        }
      } else {
        message += rz.status;
      }
      this.$bvToast.toast(message, {
        title: 'Error',
        autoHideDelay: 10000,
        variant: "danger"
      });
    },


    queryPortResult() {
      this.getGenerationResult(this.taskId).then(rz => {
        if (rz.status === 200 && rz.data.code === 0){
          clearInterval(this.interval)
          this.loading = false;
          this.resultObjects = rz.data.result.objects
          this.resultZones = rz.data.result.zones
          this.tep = rz.data.result.economyParameters
          this.goToResult();
        } else if (rz.status !== 200 || rz.data.code !== 3){
          this.showError(rz);
        } else {
          // not yet ready
        }
      })
    },

    goToResult() {
      this.$router.push({
        name: 'result',
        params: {
          resultId: this.taskId,
          portBounds: this.portBounds,
          resultObjects: this.resultObjects,
          entryPointMarkers: this.entryPointMarkers,
          resultZones: this.resultZones,
          tep: this.tep
        }
      })
    },
  },

  computed: {
    isReady() {
      return !this.loading;
    },

    disabledHint() {
      if (this.loading) {
        return "Дождитесь окончания генерации"
      }
      return ""
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/editor.css';
/deep/ .toast:not(.show) {
  display: block;
}
::placeholder{

  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

}
</style>