<template>
    <div class="container m-5 text-left">
        <b-row>
            <b-col>
                <p>
                    Контакты проекта
                </p>
            </b-col>
        </b-row>
    </div>

</template>

<script>
    export default {
        name: "Contacts"
    }
</script>

<style scoped>

</style>